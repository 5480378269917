export const employeeSalaryType = [
    {
        label: 'Все',
        value: ''
    },
    {
        label: 'Договорная',
        value: 0
    },
    {
        label: 'Фиксированная',
        value: 1
    }
];

export const employeeRole = [
    {
        label: 'Все',
        value: ''
    },
    {
        label: 'Директор',
        value: 1
    },
    {
        label: 'Технолог',
        value: 2
    },
    {
        label: 'Зав. склад',
        value: 3
    },
    {
        label: 'Швея',
        value: 4
    }
]